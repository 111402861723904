// ~~~ CONTENTFUL PAGE ~~~
/* eslint-disable */

import React from 'react'
import Helmet from 'react-helmet'
import Layout from 'components/Layout'
import Hero from 'components/Contentful/Hero'
import HowTo from 'components/Contentful/HowTo'
import IntroAndPicture from 'components/Contentful/IntroAndPicture'

const Page = props => {
  return (
    <Layout location={props.location}>
      <Helmet
        title="Erfahrungsbericht von Familie Knöpfel "
        meta={[
          { name: 'description', content: 'von Ölheizung zu Wärmepumpe ➤ Erfahrungsbericht von Familie Knöpfel ✔️ natürliches Kältemittel ✔️ Ökologisch ✔️ Hier informieren!' },
          { name: 'keywords', content: 'wärmepumpe statt ölheizung erfahrungen, wärmepumpe durch ölheizung ersetzen erfahrungen' },
        ]}
      >
        
      </Helmet>
      
      <div
          className='bg__flex--white'
          key='3Xg0Bl3baNo1PqmCGYPapV-2XT8FYUOXNcqFypBwcZjBF'
          id='familie-knoepfel-zug-zg'
        >
          <Hero
            {...{
    "type": "hero",
    "id": "2XT8FYUOXNcqFypBwcZjBF",
    "updatedAt": "2023-03-01T08:59:03.051Z",
    "title": "FAMILIE KNÖPFEL, ZUG ZG",
    "size": "Groß",
    "teaser": "Die Familie Knöpfel entschied sich, trotz ihrer erst 12 Jahre alten Ölheizung, für eine Propan-Wärmepumpe - ein klarer Gewinn für die Umwelt. Auch die Warmwasseraufbereitung wurde auf einen umweltfreundlichen Trinkwasserspeicher umgestellt. Dies ist ein deutliches Zeichen dafür, dass die Knöpfels an die Zukunft denken! ",
    "image": {
        "description": "Dieses Bild zeigt die aussenaufgestellte Wärmepumpe von Familie Knöpfel",
        "contentType": "image/png",
        "url": "//images.ctfassets.net/wbm1kpreb3m8/6hvByBHt1q5iW2NIQ1W1zS/f96deff1cb79b243aa7aeeb2d9667cb2/2301--Referenz-Story-Kn__pfel-HERO.png"
    },
    "links": [
        {
            "type": "link",
            "id": "5Rv4CldRK0aI4UGakKsISC",
            "updatedAt": "2022-04-01T07:44:51.656Z",
            "label": "Zur Referenz-Übersicht",
            "page": {
                "slug": "referenzen"
            }
        }
    ],
    "anchorName": "familie-knoepfel-zug-zg"
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--grey'
          key='3Xg0Bl3baNo1PqmCGYPapV-50SMLWzJ1xNoKQT06tm8HT'
          id=''
        >
          <HowTo
            {...{
    "type": "howTo",
    "id": "50SMLWzJ1xNoKQT06tm8HT",
    "updatedAt": "2023-03-01T09:05:32.033Z",
    "title": "FAMILIE KNÖPFEL, ZUG ZG",
    "steps": [
        {
            "type": "howToStep",
            "id": "LHgDgEE1OEymMe50lkyEI",
            "updatedAt": "2023-03-01T09:01:05.788Z",
            "title": "Klimaschonende Wärmepumpe mit natürlichem Kältemittel Propan (R290)",
            "description": "<p>\"Wir wollten aus ökologischen Gründen weg von fossilen Brennstoffen kommen\", erklärt Walter Knöpfel während des Interviews. Die Familie hat sich daher entschieden, schon vorzeitig einen umweltfreundlichen Heizungsersatz zu suchen. In ihrem Fall soll es jedoch nicht irgendeine Wärmepumpe sein, sondern eine noch umweltfreundlichere Wärmepumpe mit Propan als Kältemittel. Dieses natürliche und \"grüne\" Kältemittel mit tiefem Treibhausgaspotenzial macht das ohnehin nachhaltige und umweltschonende Heizsystem noch ökologischer.</p>\n",
            "image": {
                "description": "vorher nachher Bilder von Familie Knöpfels Untergeschoss",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/2GDSj5Wju11ElwSqP5n35C/e9f8c1a71100ce21e627a04614a12e3a/2301---Walter-Kn__pfel-UG-vorher-nachher.png"
            }
        },
        {
            "type": "howToStep",
            "id": "3UYzIuFYTMTr0iERUwbbJ0",
            "updatedAt": "2023-03-01T09:01:19.936Z",
            "title": "Wie fiel der Entscheid auf die Heizungsmacher?",
            "description": "<p>Walter Knöpfel erzählt im Interview, dass sein Nachbar ihm die Heizungsmacher AG weiterempfohlen hat, weil er selber eine Anlage von ihnen hatte einbauen lassen. Familie Knöpfel hätte jedoch trotzdem noch 3-4 Offerten von diversen Anbietern eingeholt, um den Markt zu vergleichen. Letzten Endes hat sich die Familie für die Heizungsmacher AG entschieden, weil sie die einzige Offerte bot, die transparent und aufrichtig war. Die Kostenermittlung war bereits auf der Offerte ersichtlich und sie mussten sich dadurch keine Sorgen machen, dass am Schluss noch versteckte Kosten auftauchen würden. Die Tatsache, dass die Heizungsmacher AG trotz vieler Partnerfirmen während des gesamten Zeitraums ihr einziger Ansprechpartner blieb, hat sie schlussendlich vollständig überzeugt, den Heizungsumbau über die Heizungsmacher AG abzuwickeln.</p>\n"
        },
        {
            "type": "howToStep",
            "id": "7gQF4WiLv5NdpYDe7NxBqC",
            "updatedAt": "2023-03-01T09:03:14.425Z",
            "title": "Aussenaufstellung - Erscheinungsbild und Lärmemissionen",
            "description": "<p>Walter Knöpfel erklärt, dass seine Frau die Wärmepumpe ursprünglich zu voluminös gefunden hätte. Nachdem die Wärmepumpe jedoch an einen idealen Standort, hinter dem Haus platziert worden war, hätte sie sich daran gewöhnt. Die Familie Knöpfel gibt zu, ein wenig Respekt vor den Lärmemissionen gehabt zu haben, weil sie auch von Szenarien und damit verbundenen Nachbars Streitereien gehört haben. Walter Knöpfel erklärt jedoch lächelnd, dass er sein Ohr an das Blech heran halten muss, um überhaupt etwas zu hören. Daher könne er weder zum Erscheinungsbild noch zum Lärm etwas Negatives berichten. Sie sind sogar positiv überrascht, wie gut sich die Aussenaufstellung optisch in die Umgebung einfügte.</p>\n",
            "image": {
                "description": "aussenaufgestellte Wärmepumpe von Familie Knöpfel",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/6qr6fVTWnqHdzKMlujHb63/98526102e9a224958e4bb353255a304e/2301---Walter-Kn__pfel-W__rmepumpe.png"
            }
        },
        {
            "type": "howToStep",
            "id": "7tKRi9hfDNavSgI0QK16JS",
            "updatedAt": "2023-03-01T09:04:26.741Z",
            "title": "Was wurde aus dem alten Tankraum?",
            "description": "<p>\"Es war nicht ganz einfach, den neuen Heizungsraum zu planen und umzusetzen\", erklärt Walter Knöpfel. Ursprünglich war geplant, alle Inneneinheiten im alten Tankraum unterzubringen. Doch bei der Umsetzung des Projekts stellte die Heizungsmacher AG fest, dass der Platz doch sehr knapp wird und Walter Knöpfel erhielt einen Vorschlag, mit dem er nicht zufrieden war. \"Die Inneneinheiten wären dann nicht dort, wo wir sie ursprünglich haben wollten\", erklärt Walter Knöpfel. Also schlug er nochmals vor, über die Bücher zu gehen und den ursprünglichen Plan durchzuführen. Danach konnte der Plan – wie ursprünglich abgemacht, ausgeführt werden. Schliesslich wurden zwei 1500-Liter-Öltanks aus dem alten Tankraum entfernt und die Tür des Raumes aus platztechnischen Gründen entfernt. Nachdem der Tankraum und die Öffnung zum Tankraum für die neuen Anlagen vorbereitet worden sind, konnten alle Inneneinheiten -  Warmwasserspeicher, Pufferspeicher und Trinkwasserspeicher - darin platziert werden.</p>\n",
            "image": {
                "description": "vorher/nachher Bilder vom Tankraum der Familie Knöpfel",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/61ov1taiJ9EYShhwgp8vQh/f154c8c1e205b9f637a212f7532cf682/2301---Walter-Kn__pfel-Tankraum-vorher-nachher.png"
            }
        },
        {
            "type": "howToStep",
            "id": "4tGF1sQBo8JUTFq7BQKR1b",
            "updatedAt": "2023-03-01T09:05:22.865Z",
            "title": "Familie Knöpfels Erfahrung mit der Heizungsmacher AG",
            "description": "<p>“Ich bin sehr zufrieden mit dem, was die Heizungsmacher AG versprochen und eingehalten hat. Das Gesamtprojektwurde von A-Z  organisiert und durchgeführt - ich bewerte das als sehr positiv! Die Mitarbeiter der Partnerfirmen waren freundlich, umgänglich und sehr speditiv. Die Terminvereinbarungen konnten eingehalten werden. Bei der ursprünglichen Planung gab es zwar Verschiebungen, aber für uns war das kein Problem.\" führt Walter Knöpfel aus. Hin und wieder hätte er sogar aus Freundlichkeit den Arbeitsplatz der Handwerker sauber gemacht, um ihnen die Arbeit zu erleichtern.</p>\n",
            "image": {
                "description": "aussenaufgestellte Wärmepumpe (Viessmann Vitocal 250) von Familie Knöpfel",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/2ibKCkVmlretWjTpBl8I5V/ccf772925c4b36d5041c581058f9b3ed/2301---Walter-Kn__pfel-W__rmepumpe-drittes-Bild.png"
            }
        }
    ]
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--white'
          key='3Xg0Bl3baNo1PqmCGYPapV-7jD5c0PUP3C0hMRQy2Xg1Q'
          id='feedback-familie-knoepfel'
        >
          <IntroAndPicture
            {...{
    "type": "introAndPicture",
    "id": "7jD5c0PUP3C0hMRQy2Xg1Q",
    "updatedAt": "2023-03-01T09:12:12.173Z",
    "title": "Feedback Familie Knöpfel",
    "anchorName": "feedback-familie-knoepfel",
    "text": "<p><strong>Heizungsmacher-Team:</strong> Liebe Familie Knöpfel, vielen Dank für Ihre Bewertung und  Weiterempfehlung. Es freut uns sehr, dass Sie mit unserem Service und unseren Produkten zufrieden sind.</p>\n",
    "image": {
        "description": "Kundenfeedback: 5 Sterne-Bewertung",
        "contentType": "image/svg+xml",
        "url": "//images.ctfassets.net/wbm1kpreb3m8/7dFyVjY2BukXpteLJih95X/047c09b3094ac7fb446dc6015dcddd65/5_Sterne-Bewertung.svg"
    }
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--grey'
          key='3Xg0Bl3baNo1PqmCGYPapV-5jl6yjDwGax05p7DpzlEBw'
          id='wuenschen-sie-eine-beratung'
        >
          <IntroAndPicture
            {...{
    "type": "introAndPicture",
    "id": "5jl6yjDwGax05p7DpzlEBw",
    "updatedAt": "2023-08-30T09:41:16.098Z",
    "title": "Wünschen Sie eine Beratung?",
    "anchorName": "wuenschen-sie-eine-beratung",
    "text": "<p>Sie interessieren sich für eine Wärmepumpe und sind noch nicht sicher, ob dies die richtige Wahl ist? Besuchen Sie unseren kostenlosen Heizungs-Check oder lassen Sie sich von unseren <a href=\"https://www.heizungsmacher.ch/ueber-uns/\" title=\"ueber-uns\">Heizexperten</a> beraten.</p>\n",
    "link": {
        "type": "link",
        "id": "7p7eaG5GRI8A1fElOkNxIb",
        "updatedAt": "2022-06-28T08:23:33.929Z",
        "label": "Online Heizungs-Check",
        "url": "https://www.heizungsmacher.ch/konfigurator"
    }
}} location={props.location}
          />
        </div>
    </Layout>
  )
}

export default Page
